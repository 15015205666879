<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload :api="this.api" @close="fetch()"/>
        <v-data-table
            v-if="!api.isLoading"
            :search="search"
            :items="data"
            :headers="dataHeader"
            class="elevation-1">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3" >
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    List of invoice unmatched
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="fetch()"  class="mx-3 mt-3">
                                    Refresh
                                </v-btn> 
                               
                                <JsonCSV :data="data" class="mx-2 mt-1">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>

                            </v-row>
                            <v-row
                                class="ma-2">
                                <span
                                    style="font-size:20px">
                                        List of user in excel that can be found at the list of user in database
                                </span>
                            </v-row>    
                            <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense 
                                    outlined 
                                    label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template
                v-slot:item.send_date="{item}">
                <span
                    v-if="item.send_date!=null">
                    {{ convertTimeZone(item.send_date) }}
                </span>
                <span   
                    v-else>
                        -
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../common/ASuccessWithoutReload.vue';
export default {
    components:{
    ASuccessWithoutReload
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        expanded:[],
        search:"",
        data:null,
        dataHeader:[
        {
                text:'#',
                value:'index',
            },
            {
                text:'Company ( database )',
                value:'company_name',
            },
            {
                text:'Company ( excel )',
                value:'excel_company_name'
            },
            {
                text:'Invoice no.',
                value:'invoice_no',
            },
            {
                text:'Invoice to name',
                value:'requester_name',
            },
            {
                text:'Invoice to address',
                value:'requester_address',
            },
            {
                text:'Send date',
                value:'send_date',
            }
        
        ],
        AAERole:[
        'client',
        'consultant',
        'tax_expert',
        'admin',
        'manager',
        ],
        VisionRole:[
        'salesperson',
        'customer_service',
        'sales_team_lead',
        'vision_admin',
        'operation',
        'sales_manager',
        'developer',
        'marketing',
        ],
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
        },
        isFirstLoading:true,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="fixStaffListUsingExcel") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully fix the staff list";
            }
            if(resp.class==="getAllUnmatchedInvoice") {
                    this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchInvoiceListUnmatched());
        },
        fetchInvoiceListUnmatched() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/invoice/unmatched";
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        transfer() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/staff_list/fix/"+this.$store.getters.getUserId;
            this.$api.fetch(tempApi);
        },  
     
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LL');
        },
    }
}
</script>