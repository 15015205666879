<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            :items="data.data"
            :lazy="true"
            :loading="api.isLoading"
            :options.sync="options"
            :headers="dataHeader"
            :items-per-page.sync="options.itemsPerPage"
            :server-items-length="data.total"
            :search="search"
            item-key="user_id"
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            :footer-props="{
                itemsPerPageOptions:[20,40,100],
                showCurrentPage:true,
            }"
            @update:options="onPaginationUpdate"
            class="elevation-1">
            <template
                v-slot:item.index="{item}">
                {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
            </template>
           
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                        <v-row no-gutters>
                            <v-col class="mt-3 mx-3" >
                                <v-row class="mb-3">
                                    <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                        Invoice list
                                    </v-toolbar-title>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="fetch()"  class="mx-3 mt-1">
                                        Refresh
                                    </v-btn> 
                                </v-row>
                                <v-row>
                                        <v-text-field
                                        v-model="search"
                                        dense
                                        label="Search"
                                        outlined
                                        @input="handleSearchInput"
                                        clearable>
                                    </v-text-field>
                                </v-row>    
                            </v-col>
                        </v-row>
                    </v-toolbar>
                
            </template>
            <template   
                v-slot:item.company_name="{item}">
                <router-link
                    :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                    {{ item.company_name }}
                </router-link>
            </template>
            <template
                v-slot:item.send_date="{item}">
                {{ convertTimeZone(item.send_date) }}
            </template>
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        expanded:[],
        search:"",
        data:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Invoice no.',
                value:'invoice_no',
            },
            {
                text:'Invoice to name',
                value:'requester_name',
            },
            {
                text:'Invoice to address',
                value:'requester_address',
            },
            {
                text:'Send date',
                value:'send_date',
            }
           
        ],
        AAERole:[
        'client',
        'consultant',
        'tax_expert',
        'admin',
        'manager',
        ],
        VisionRole:[
        'salesperson',
        'customer_service',
        'sales_team_lead',
        'vision_admin',
        'operation',
        'sales_manager',
        'developer',
        'marketing',
        ],
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
        },
        isFirstLoading:true,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getAllInvoice") {
                    this.data = resp.data;
                    this.isFirstLoading = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchInvoiceList());
        },
        fetchInvoiceList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/developer/excel/invoice?page="+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&search="+this.options.search+"&rowsPerPage="+this.options.itemsPerPage;
            return tempApi;
        },
        onPaginationUpdate(pagination){

        if(this.isFirstLoading){
            this.isFirstLoading = false;
        }
        else{
            this.options = this.$_.clone(pagination);
            this.fetch();
        }
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        handleSearchInput() {
            setTimeout(()=>{
                this.options.search = this.search;
            },1000);
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LL');
        },
    }
}
</script>